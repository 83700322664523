<template>
  <p class="clearfix mb-0">
    <span class="float-left d-block d-md-inline-block mt-25">
      <span>Powered by</span>
      <b-link
        class="ml-25"
        href="https://eambulans.pl"
        target="_blank"
        rel="nofollow"
      >eAmbulans</b-link>
      <span class="d-none d-md-inline-block ml-25">- Wspomagamy transport medyczny</span>
    </span>

    <span
      v-if="version"
      class="float-right mt-25"
    >
      <span class="d-none d-md-inline-block">{{ $t('shared.version') }}:</span> {{ version }}
    </span>
  </p>
</template>

<script>

export default {
  data() {
    return {
      version: null,
    }
  },
  mounted() {
    this.version = window.appVersion
  },
}
</script>
